<template>
  <div class="p-field">
    <Fieldset legend="Discussion" :toggleable="true" class="p-mt-4">
      <div class="p-fluid p-grid">
        <div class="p-field p-col-12 p-md-8 p-ml-2">
          <Editor  v-model="comment" editorStyle="height: 170px" />
          <label class="error-message"> {{ validation.firstError("comment") }}</label>
        </div>
        <div class="p-field p-col-12 p-md-3" style="padding:0.5rem !important">
        <div class="p-d-flex">
        <div class="p-mr-2">
         <Button label="Update Comment" class="p-button-secondary" @click="handleUpdateComment()" icon="fas fa-plus" v-if="isEditing"/>
          <Button label="Add Comment" class="p-button-secondary" @click="handleAddComment()" icon="fas fa-plus" v-else/>
        </div>
        <div>
           <Button label="Clear" class="p-button-secondary" @click="clear()" icon="fas fa-times"/>       
        </div>  
        </div>
        </div> 
      </div>
    <ul class="timelineul">
        <li v-for="(appComment, index) in appComments" :key="index" class="timelineli">
          <div  class="d-flex flex-column">
            <div v-html="appComment.comment"></div>
            <div class="p-d-flex">
              <p class="p-mr-3 fsmall"> By {{ appComment.created_by }} at {{ appComment.created_at | formatDateTimeMT }}</p>
              <div class="p-mr-1">
                <Button icon="fas fa-pencil-alt" class="p-button-rounded p-button-text p-button-plain" @click="handleEditComment(appComment,index)"/>
              </div>
              <div class="p-mr-1">
               <Button icon="fas fa-trash-alt" class="p-button-rounded p-button-text p-button-plain" @click="handleRemoveComment(index)"/>
              </div>
            </div>
          </div>
        </li>
      </ul>
        <!-- <div v-for="(appcomment, index)  of appComments"  :key="index">
        <Message severity="success" @close="handleRemoveComment(index)">
        <span ><label  class="p-mr-1">URL:</label>{{appcomment}}</span>      
        </Message>
       </div>     -->
    </Fieldset>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import Vue from "vue";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
export default {
  created() {},
  methods: {
   handleAddComment(){
    this.$validate()
      .then((success) => {
          if (success) {
            let _comment= this.comment;
            this.addComment(_comment);
            this.clear();
          }
      });
   },
  handleUpdateComment(){
    this.$validate()
      .then((success) => {
          if (success) {
            let _comment= this.comment;
            let _index= this.currntIndex;
            this.updateComment(_comment,_index);
            this.clear();
          }
      });
   },
   handleRemoveComment(index){
     let _index= index;
     this.removeComment(_index)
   },
   handleEditComment(comment,Index){
     let _comment= comment;
     this.comment= _comment.comment;
     this.isEditing=true;
    this.currntIndex=Index;
   },
   clear(){
    this.isEditing=false;
    this.comment="";
    this.currntIndex=null;
    this.validation.reset();
   } 
  },
  props: {
    appComments: { type: Array },
    addComment: { type: Function },
    removeComment:{type:Function},
    updateComment: {type:Function},
  },
  validators: {
    "comment": function (value) {
      return Validator.value(value).required();
    }
  },
  watch: {},
  data() {
    return {
      comment:"",
      isEditing:false,
      currntIndex:null,
    }
  },
};
</script>
<style scoped>
.floatlabel {
  border-radius: 2px;
  font-weight: 700;
  font-size: 13px !important;
}
.ptext-overflow{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:400px !important;
  font-weight: 400;
}
.pdesc-overflow{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:1200px !important; 
  font-weight: 400 ;  
}
.overflowlabel{
    font-weight: bold !important;
}
.fsmall{
font-size: 11px !important;
padding-top: 8px !important;
}
</style>