<template>
  <div class="p-field">
    <Fieldset legend="Version" :toggleable="true" class="p-mt-4">
      <div class="p-fluid p-grid">
        <div class="p-field p-col-12 p-md-4">
          <span class="p-float-label">
           <InputText id="inputtext" type="text" v-model="version.title" :disabled="isEditing"  />
            <label for="inputtext" class="floatlabel">Name</label>
          </span>
        <label class="error-message"> {{ validation.firstError("version.title") }}</label>
         <label class="error-message" v-if="isExisting">Version name should be unique </label>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <span class="p-float-label">
            <Calendar v-model="version.start_date"  dateFormat="dd/mm/yy" :minDate="minDate" :maxDate="maxDate" :manualInput="false" :disabledDays="[0,6]"/>
            <label for="inputtext" class="floatlabel">Start Date</label>
          </span>
        <label class="error-message"> {{ validation.firstError("version.start_date") }}</label>
        </div>              
        <div class="p-field p-col-12 p-md-4">
          <span class="p-float-label">
            <Calendar v-model="version.go_live_date"  dateFormat="dd/mm/yy" :minDate="minDate" :maxDate="maxDate" :manualInput="false" :disabledDays="[0,6]"/>
            <label for="inputtext" class="floatlabel">GoTo Live Date</label>
          </span>
        <label class="error-message"> {{ validation.firstError("version.go_live_date") }}</label>
        </div>
        <div class="p-field p-col-12 p-md-9 p-ml-2">
         <label for="inputtext" class="floatlabel">Description</label>
          <Editor  v-model="version.description" editorStyle="height: 170px" />
        </div>
        <div class="p-d-flex"  style="margin-top:2.5% !important">
            <div class="p-mr-2">
              <Button label="Update Version" class="p-button-secondary" @click="handleUpdateVersion()" icon="fas fa-plus" v-if="isEditing"/>
              <Button label="Add Version" class="p-button-secondary" @click="handleAddVersion()" icon="fas fa-plus" v-else/>
            </div>
            <div class="p-mr-2">
              <Button label="Clear Version" class="p-button-secondary" @click="clearVersion()" icon="fas fa-times"/>
            </div>
        </div> 
      </div>
       <!-- <div v-for="(appVersion, index)  of appVersions"  :key="index">
        <Message severity="success" @close="handleRemoveVersion(index)">
        <div class="p-grid">
            <div class="p-col-4"><p class="ptext-overflow"> <label class="p-mr-1 overflowlabel">Name:</label>  {{appVersion.name}}</p></div>
            <div class="p-col-4"><p class="ptext-overflow"> <label class="p-mr-1 overflowlabel">Start Date:</label> {{appVersion.start_date | FormatDateTime}}</p></div>
            <div class="p-col-4"><p class="ptext-overflow"> <label  class="p-mr-1 overflowlabel">GoTo Live Date:</label>  {{appVersion.go_live_date|FormatDateTime }}</p></div> 
        </div>
        <div class="p-grid">
        <div class="p-col-10"><p class="pdesc-overflow"><label  class="p-mr-1 overflowlabel">Description:</label>{{appVersion.description}}</p></div> 
        </div>     
        </Message>
       </div> -->
      <div v-if="appVersions">
        <DataTable :value="appVersions">
          <template #empty> No Versions found. </template>
          <Column field="title" header="Name" headerClass="tablebackground"></Column>
          <Column field="start_date" header="Start Date" headerClass="tablebackground">
           <template #body="slotProps">
           {{slotProps.data.start_date|FormatDateTime }}
           </template>
          </Column>
          <Column field="go_live_date" header="GoTo Live Date" headerClass="tablebackground">
           <template #body="slotProps">
           {{slotProps.data.go_live_date|FormatDateTime }}           
           </template>
          </Column>
          <Column field="description" header="Description" headerClass="tablebackground">
               <template #body="slotProps">
              <div class="p-d-flex">
                <div v-html="slotProps.data.description"  class="appdesoverflow p-mr-3 "></div>
              <div v-b-popover.html.hover.top="slotProps.data.description" v-if="slotProps.data.description">
                <span class="pi pi-info-circle "></span></div> 
              </div>
              </template>
            </Column>
          <Column header="Actions" bodyClass="actions" headerClass="actions tablebackground">
          <template #body="slotProps">
            <Button  icon="fas fa-pencil-alt"  class="p-button-secondary p-mr-2"  @click="handleEditVersion(slotProps.data,slotProps.index)"/>
            <Button  icon="fas fa-trash-alt"  class="p-button-secondary"  @click="handleRemoveVersion(slotProps.index)"/>
            </template>
          </Column>
        </DataTable>
        </div>
    </Fieldset>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import Vue from "vue";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
export default {
  created() {
      let today = new Date();
      let year = today.getFullYear();
      this.minDate = new Date();
      this.minDate.setFullYear(year-1);
      this.maxDate = new Date();
      this.maxDate.setFullYear(year+1);
  },
  methods: {
    handleAddVersion(){
    const appVersions= this.appVersions;
    const appVersion= this.version;
    const appTitle= appVersions.map(appv => appv.title.toLowerCase());
    this.isExisting = appTitle.includes(appVersion.title.toLowerCase());
    setTimeout(() => {
      this.isExisting=false;
    }, 2000);
    this.$validate()
      .then((success) => {
        if (success && !this.isExisting) {
          let _version= this.version;
          this.addVersion(_version)
          this.clearVersion();
        }
      });
    },
    handleUpdateVersion(){
    this.$validate()
      .then((success) => {
        if (success) {
          let _version= this.version;
          let _index= this.currntIndex;
          this.updateVersion(_version,_index)
          this.clearVersion();
          this.isEditing=false;
          this.currntIndex=null;
        }
      });
    },
    handleRemoveVersion(index){
    let _index= index;
    this.removeVersion(_index);
    },
    clearVersion(){
      this.version={
      name:"",
      start_date:"",
      go_live_date:"",
      description:""        
      }
      this.isEditing=false;
      this.validation.reset();
    },
    handleEditVersion(version,index){
      let _version= {...version} ;
      this.version =_version;
      this.currntIndex=index;
      this.isEditing=true;
    },
  },
  validators: {
    "version.title": function (value) {
      return Validator.value(value).required();
    },
    "version.start_date": function (value) {
      return Validator.value(value).required();
    },
    "version.go_live_date": function (value) {
      return Validator.value(value).required().custom(()=>{
        const startdate= this.version.start_date;
        if(startdate){
          return value < startdate?"Live date should be greater-than startDate":""
        }else{
          setTimeout(()=>{this.version.go_live_date=""},3000)
          return "Select start date before selecting the live date";
         
        }
      });
    }
  },
  props: {
    appVersions: { type: Array },
    addVersion:{type:Function},
    removeVersion:{type:Function},
    updateVersion:{type:Function}
  },
  watch: {},
  data() {
  return {
    isEditing:false,
    isExisting:false,
    currntIndex:null,
    version: {
      title:"",
      start_date:"",
      go_live_date:"",
      description:""
    },
    minDate: null,
    maxDate: null,
  }
  },
};
</script>
<style scoped>
.floatlabel {
  border-radius: 2px;
  font-weight: 700;
  font-size: 13px !important;
}
.ptext-overflow{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:400px !important;
  font-weight: 400;
}
.pdesc-overflow{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:1200px !important; 
  font-weight: 400 ;  
}
.overflowlabel{
    font-weight: bold !important;
}
</style>