<template>
  <div class="p-field">
  <Message severity="warn"  v-if="linkError" @close="removeWarnMessage()">Link Type Should Be Unique While Adding Links</Message>
    <Fieldset legend="Links" :toggleable="true" class="p-mt-4">
      <div class="p-fluid p-grid">
        <div class="p-field p-col-12  p-md-4">
          <span class="p-float-label">
          <Dropdown v-model="link.title" :options="appMasterLinks" optionLabel="name"  
          :filter="true" filterPlaceholder="Search For Links">
            <template #option="slotProps">
              <div><span><i :class="slotProps.option.icon" class="p-mr-1"></i></span>{{slotProps.option.name}}</div>
           </template>
          </Dropdown>
            <label for="inputtext" class="floatlabel">Link Type</label>
          </span>
        <label class="error-message"> {{ validation.firstError("link.title") }}</label>
        </div>
          <div class="p-field p-col-12 p-md-4">
          <span class="p-float-label">
           <InputText id="inputtext" type="text" v-model="link.url"/>
            <label for="inputtext" class="floatlabel">Link URL</label>
          </span>
          <label class="error-message"> {{ validation.firstError("link.url") }}</label>
        </div>
        <div class="p-d-flex p-mt-4">
            <div class="p-mr-2">
              <Button label="Update Link" class="p-button-secondary" @click="handleUpdateLink()" icon="fas fa-plus" v-if="isEditing"/>
              <Button label="Add Link" class="p-button-secondary" @click="handleAddLink()" icon="fas fa-plus" v-else/>
            </div>
            <div class="p-mr-2">
              <Button label="Clear Link" class="p-button-secondary" @click="clearlink()" icon="fas fa-times"/>
            </div>
        </div>
      </div>
       <!-- <div v-for="(link, index)  of appLinks"  :key="index">
        <Message severity="success" @close="handleRemoveAppLink(index)">
        <div><label class="p-mr-1">Name:</label>{{link.title}}</div>
        <div><label  class="p-mr-1">URL:</label>
          <a target="_blank" v-bind:href="link.url" class="alabel"><i class="fas fa-external-link-alt"></i> {{link.url}} </a>
           <label @click="handleEditapplink(link)" class="p-ml-1" style="cursor:pointer !important"><i class="fas fa-edit" ></i> </label>
        </div>
        </Message>
       </div> -->
       <div v-if="appLinks.length">
        <DataTable :value="appLinks">
          <Column field="title" header="Name" headerClass="title tablebackground" bodyClass="title"></Column>
          <Column field="url" header="URL" headerClass="applinkurl tablebackground" bodyClass="applinkurl">
             <template #body="slotProps">
             <a target="_blank" v-bind:href="slotProps.data.url" class="alabel"><i class="fas fa-external-link-alt" ></i> {{slotProps.data.url}} </a>
             </template>
          </Column>
          <Column header="Actions" headerClass="applinkactions tablebackground" bodyClass="applinkactions">
          <template #body="slotProps">
          <Button  icon="fas fa-pencil-alt" class="p-mr-2 p-button-secondary" 
           @click="handleEditapplink(slotProps.data,slotProps.index)" :title="`Edit ${slotProps.data.title}`"/>
          <Button  icon="fas fa-trash-alt"   class="p-button-secondary" @click="handleRemoveAppLink(slotProps.index)" :title="`Remove ${slotProps.data.title}`"/>
            </template>
          </Column>
        </DataTable>
        </div>
    </Fieldset>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import Vue from "vue";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
export default {
  validators: {
    "link.title": function (value) {
      return Validator.value(value).required();
    },
    "link.url": function (value) {
      return Validator.value(value).required().url().custom(() =>
      {
      if (!Validator.isEmpty(value)) {
        var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,3})))(?::\d{2,3})?(?:\/\S*)?$/;
          if (!regexp.test(value)) {
              return 'Invalid url';
          }
        }
      });
    }
  },
  created() {},
  methods: {
    handleAddLink(){
    this.$validate()
      .then((success) => {
          if (success) {
            let _link= this.link;
            let _applink = this.appLinks.find(app => app.title ==_link.title["name"])
            this.addLink(_link);
            this.clearlink();
          }
      });
    },
    handleUpdateLink(){
    this.$validate()
      .then((success) => {
          if (success) {
            let _link= this.link;
            let _index= this.currntIndex;
            let _applink = this.appLinks.find(app => app.title ==_link.title["name"])
            this.updateLink(_link,_index);
            this.clearlink();
            this.isEditing=false;
            this.currntIndex=null;
          }
      });
    },
    handleRemoveAppLink(index){
     let _index = index;
     this.removeLink(_index)

    },
    removeWarnMessage(){
    this.linkError=false;
    },
    handleEditapplink(link,index){
      let _link= {
        title: this.appMasterLinks.find(lnk => lnk.name == link.title),
        url:link.url
      };
      this.link =_link;
      this.isEditing=true;
      this.currntIndex=index;
    },
    clearlink(){
      this.link= {
        title:"",
        url:""
      },
      this.validation.reset();
      this.isEditing=false;
    }
  },
  props: {
    appMasterLinks: { type: Array },
    appLinks:{type:Array},
    addLink: { type: Function },
    removeLink:{type:Function},
    updateLink:{type:Function}
  },
  watch: {},
  data() {
	return {
		link: {
      title:"",
      url:""
    },
		skeletonTable:{
    columns:[
      {field: 'title', header: 'Title'},
      {field: 'url', header: 'URL'},
      {field: 'Actions', header: 'Actions'}
      ],
    isRequiredHeader:false
    },
    isAdding:true,
    linkError:false,
    isEditing:false,
    currntIndex:null,
	}
  },
};
</script>
<style scoped>
.floatlabel {
  border-radius: 2px;
  font-weight: 700;
  font-size: 13px !important;
}
</style>