<template>
  <div class="p-field">
    <Fieldset legend="Environment Details" :toggleable="true" class="p-mt-4">
      <div class="p-fluid p-grid">
        <div class="p-field p-col-12 p-md-4">
          <span class="p-float-label">
           <InputText id="inputtext" type="text" v-model="appEnvironment.name" />
            <label for="inputtext" class="floatlabel">Name</label>
          </span>
        <label class="error-message"> {{ validation.firstError("appEnvironment.name") }}</label>
        <label class="error-message" v-if="isExisting">Environment name should be unique </label>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <span class="p-float-label">
           <InputText id="inputtext" type="text" v-model="appEnvironment.title" />
            <label for="inputtext" class="floatlabel">Title</label>
          </span>
        <label class="error-message"> {{ validation.firstError("appEnvironment.title") }}</label>
        </div>              
        <div class="p-field p-col-12 p-md-4">
          <span class="p-float-label">
           <InputText id="inputtext" type="text" v-model="appEnvironment.url" />
            <label for="inputtext" class="floatlabel">URL</label>
          </span>
        <label class="error-message"> {{ validation.firstError("appEnvironment.url") }}</label>
        </div>
        <div class="p-field p-col-12 p-md-8 p-ml-2">
         <label for="inputtext" class="floatlabel">Description</label>
          <Editor  v-model="appEnvironment.description" editorStyle="height: 170px" />
        </div>
        <div class="p-d-flex"  style="margin-top:2.5% !important">
            <div class="p-mr-2">
               <Button label="Update Environment" class="p-button-secondary" @click="handleUpdateEnvironment()"  icon="fas fa-plus" v-if="isEditing"/>
               <Button label="Add Environment" class="p-button-secondary" @click="handleAddEnvironment()"  icon="fas fa-plus" v-else/>
            </div>
            <div class="p-mr-2">
              <Button label="Clear Environment" class="p-button-secondary" @click="clearEnvironment()"  icon="fas fa-times"/>
            </div>
        </div>        
      </div>
       <!-- <div v-for="(appEnvironment, index)  of appEnvironments"  :key="index">
        <Message severity="success" @close="handleRemoveEnvironment(index)">
        <div class="p-grid">
            <div class="p-col-4"><p class="ptext-overflow"> <label class="p-mr-1 overflowlabel">Name:</label>  {{appEnvironment.name}}</p></div>
            <div class="p-col-4"><p class="ptext-overflow"> <label class="p-mr-1 overflowlabel">Title:</label> {{appEnvironment.title}}</p></div>
            <div class="p-col-4"><p class="ptext-overflow"> <label  class="p-mr-1 overflowlabel">URL:</label>  {{appEnvironment.url}}</p></div> 
        </div>
        <div class="p-grid">
          <div class="p-col-10">
            <p class="pdesc-overflow"><label  class="p-mr-1 overflowlabel">Description:</label>{{appEnvironment.description}}</p>
          </div> 
          <div class="p-col-1">
            <label @click="handleEditEnvironment(appEnvironment)" class="p-ml-1" style="cursor:pointer !important"><i class="fas fa-edit" ></i> </label>
          </div>
        </div>     
        </Message>
       </div> -->
    </Fieldset>
          <div v-if="appEnvironments.length">
          <DataTable :value="appEnvironments"
           :expandedRows.sync="expandedRows"
           @row-expand="onRowExpand"
            dataKey="title">
            <Column :expander="true" :headerStyle="{'width': '3rem'}"  headerClass="tablebackground"/>
            <Column field="name" header="Name" headerClass="tablebackground custname" bodyClass="custname"></Column>
            <Column field="title" header="Title" headerClass="tablebackground custtitle" bodyClass=""></Column>
            <Column field="description" header="Description" headerClass="tablebackground">
              <template #body="slotProps">
              <div class="p-d-flex">
                <div v-html="slotProps.data.description"  class="appdesoverflow p-mr-3 "></div>
                <div v-b-popover.html.hover.top="slotProps.data.description" v-if="slotProps.data.description">
                    <span class="pi pi-info-circle "></span></div> 
              </div>
              </template>
            </Column>
            <Column field="url" header="URL" headerClass="custurl tablebackground" bodyClass="custurl">
              <template #body="slotProps">
              <div class="d-flex">
              <a target="_blank" v-bind:href="slotProps.data.url" class="alabel p-mr-2"><i class="fas fa-external-link-alt"></i>Link </a> 
              <div v-b-popover.hover.top="slotProps.data.url">
                <span class="pi pi-info-circle "></span></div> 
              </div>
              </template>
            </Column>
            <Column header="Actions" bodyClass="actions" headerClass="actions tablebackground">
            <template #body="slotProps">
            <div class="d-flex">
            <Button  icon="fas fa-pencil-alt" class="p-mr-2 p-button-secondary"  @click="handleEditEnvironment(slotProps.data,slotProps.index)"/>
            <Button  icon="fas fa-trash-alt"  class="p-mr-2 p-button-secondary"  @click="handleRemoveEnvironment(slotProps.index)"/>
            <Button  icon="fas fa-plus" class="p-button-secondary" @click="handleAddEnvlink(slotProps.data)"/>
            </div>
              </template>
            </Column>
                <template #expansion="slotProps">
                  <div class="orders-subtable">
                      <DataTable :value="slotProps.data.links" dataKey="title">
                          <template #empty>
                          No environment links are found.
                          </template> 
                        <Column field="title" header="Name"></Column>
                        <Column field="url" header="URL">
                          <template #body="slotProps">
                          <a target="_blank" v-bind:href="slotProps.data.url" class="alabel"><i class="fas fa-external-link-alt"></i> {{slotProps.data.url}} </a>
                          </template>
                        </Column>
                        <Column header="Actions" bodyClass="actions" headerClass="actions">
                        <template #body="slotProps">
                        <Button  icon="fas fa-pencil-alt" class="p-mr-2 p-button-secondary"  @click="handleEditapplink(slotProps.data,slotProps.index)"/>
                        <Button  icon="fas fa-trash-alt"  class="p-button-secondary" @click="handleRemoveEnvAppLink(slotProps.index)"/>
                          </template>
                        </Column>
                      </DataTable>
                  </div>
              </template>
          </DataTable>
        </div>
    <Dialog :header='appEnvironment.title' :visible.sync="displayAppEnvLink" 
    :style="{width: '70vw'}" :modal="true" class="p-p-0" @hide="closeDialog">
        <Fieldset legend="Links" :toggleable="true">
            <div class="p-fluid p-grid">
              <div class="p-field p-col-12  p-md-4">
                <span class="p-float-label">
                <Dropdown v-model="link.title" :options="appMasterLinks" optionLabel="name" appendTo="body"
                 :filter="true" filterPlaceholder="Search For Links">
                  <template #option="slotProps">
                    <div><span><i :class="slotProps.option.icon" class="p-mr-1"></i></span>{{slotProps.option.name}}</div>
                </template>
                </Dropdown>
                  <label for="inputtext" class="floatlabel">Link Type</label>
                </span>
              <label class="error-message" v-if="!envapplink() && isSubmit">Rrequired</label>
              </div>
                <div class="p-field p-col-12 p-md-4">
                <span class="p-float-label">
                <InputText id="inputtext" type="text" v-model="link.url"/>
                  <label for="inputtext" class="floatlabel">Link URL</label>
                </span>
                <label class="error-message" v-if="!envapplink() && isSubmit">Required</label>
              </div>
              <div class="p-d-flex p-mt-4">
                  <div class="p-mr-2">
                    <Button label="update Link" class="p-button-secondary" @click="handleUpdateLink()" icon="fas fa-plus" v-if="isEditing"/>
                    <Button label="Add Link" class="p-button-secondary" @click="handleAddLink()" icon="fas fa-plus" v-else/>
                  </div>
                  <div class="p-mr-2">
                    <Button label="Clear Link" class="p-button-secondary" @click="clearlink()" icon="fas fa-times" v-if="!isEditing"/>
                  </div>
              </div>
            </div>
            <div v-if="links.length">
            <DataTable :value="links">
              <Column field="title" header="Name" headerClass="tablebackground"></Column>
              <Column field="url" header="URL" headerClass="tablebackground">
                <template #body="slotProps">
                <a target="_blank" v-bind:href="slotProps.data.url" class="alabel"><i class="fas fa-external-link-alt"></i> {{slotProps.data.url}} </a>
                </template>
              </Column>
              <Column header="Actions" headerClass="actions tablebackground" bodyClass="actions">
              <template #body="slotProps">
              <Button  icon="fas fa-pencil-alt" class="p-mr-2 p-button-secondary"  @click="handleEditapplink(slotProps.data,slotProps.index)"/>
              <Button  icon="fas fa-trash-alt" class="p-button-secondary"  @click="handleRemoveAppLink(slotProps.index)"/>
                </template>
              </Column>
            </DataTable>
          </div>
          </Fieldset>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times"  @click="closeModal" class="p-button-secondary" v-if="!isEditing"/>
            <Button label="Add Links" icon="pi pi-plus" @click="handleAddEnvironmentLinks()" autofocus  class="p-button-secondary" v-if="!isEditing"/>
        </template>
    </Dialog>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import Vue from "vue";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
export default {
  created() {},
  methods: {
    handleAddEnvironment(){
    const environments= this.appEnvironments;
    const environment= this.appEnvironment;
    const envNames= environments.map(env => env.name.toLowerCase());
    this.isExisting = envNames.includes(environment.name.toLowerCase());
    setTimeout(() => {
      this.isExisting=false;
    }, 2000);
    this.$validate()
      .then((success) => {
       if (success && !this.isExisting) {
        let env= this.appEnvironment;
        this.addEnvironment(env);
        this.clearEnvironment();
        }
      });
    },
    handleRemoveEnvironment(index){
     let _index = index;
     this.removeEnvironment(_index)
    },
    handleUpdateEnvironment(){
    this.$validate()
      .then((success) => {
       if (success) {
        let env= this.appEnvironment;
        let index= this.currntIndex;
        this.UpdateEnvironment(env,index);
        this.appEnvironment={};
        this.isEditing=false;
        this.currntIndex=null;
        }
      });
    },
    clearEnvironment(){
      this.appEnvironment= {
        name:"",
        title:"",
        url:"",
        description:"",
        links:[]  
      }
      this.validation.reset();
    },
    handleEditEnvironment(environment,index){
      let _environment = { ...environment } ;
      let _index= index;
      this.appEnvironment= _environment;
      this.currntIndex=_index;
      this.isEditing=true;
    },
    handleAddEnvlink(environment){
      this.displayAppEnvLink=true;
      let _environment =environment;
      this.appEnvironment= _environment;
    },
    closeModal() {
    this.displayAppEnvLink = false;
    this.links=[];
    this.clearEnvironment(); 
    },
    clearlink(){
      this.link={
        title:"",
        url:""  
      }
     this.validation.reset();
     this.isSubmit=false;
    },
    handleAddLink(){
      this.isSubmit=true;
      if (this.envapplink()) {
        let _link= this.link;
        let link = {
          title: _link.title["name"],
          created_by: this.$store.getters["login/getUser"].name,
          is_active: true,
          url: _link.url,
          };
          this.links.push(link);
          this.clearlink();
      }
    },
    envapplink(){
      let _link= this.link;
      return _link.title !="" && _link.url !=""
    },
    handleUpdateLink(){
    // this.$validate()
    //   .then((success) => {
      if (this.envapplink()) { 
          let _link= this.link;
          let _index= this.currntIndex;
          let link = {
            title: _link.title["name"],
            created_by: this.$store.getters["login/getUser"].name,
            is_active: true,
            url: _link.url,
           };
           this.expandedRows[0].links[_index] = link;
           this.isEditing=false;
           this.displayAppEnvLink = false;
           this.link={};
           this.currntIndex=null;
       }
      // });
    },
    handleEditapplink(envlink,index){
    this.displayAppEnvLink = true;
    this.appEnvironment = this.expandedRows[0];
      let _link= {
        title: this.appMasterLinks.find(lnk => lnk.name == envlink.title),
        url:envlink.url
      };
      this.link =_link;
      this.isEditing=true;
      this.currntIndex=index;
    },
    handleRemoveAppLink(_index) {
      this.links.splice(_index, 1);
    },
    onRowExpand(event) {
        if(this.expandedRows.length > 1){
          this.expandedRows.splice(0,1);
        }
      },
    handleRemoveEnvAppLink(_index){
      this.removeEnvironmentLink(_index,this.expandedRows[0].id);
    },
    handleAddEnvironmentLinks(){
       let _links =this.links;
       this.appEnvironment.links=this.appEnvironment.links||[];
       _links.forEach(element => {
         this.appEnvironment["links"].push(element);
       });
       this.addEnvironmentLinks(this.appEnvironment)
       this.displayAppEnvLink=false;
       this.links=[];
    },
    closeDialog(){
      this.link = {};
      this.isEditing = false;
    },
  },
  props: {
    appTypes: { type: Array },
    addEnvironment: { type: Function },
    addEnvironmentLinks:{type:Function},
    removeEnvironment:{type:Function},
    appEnvironments:{type:Array},
    appMasterLinks: { type: Array },
    UpdateEnvironment:{type:Function},
    removeEnvironmentLink:{type:Function}
  },
  watch: {},
  data() {
    return {
      isExisting:false,
      appEnvironment: {
        name:"",
        title:"",
        url:"",
        description:"",
        links:[]
      },
      displayAppEnvLink:false,
      link: {
        title:"",
        url:""
      },
      links:[],
       expandedRows: [],
       isEditing:false,
       currntIndex:null,
       isSubmit:false,
    }
  },
  validators: {
    "appEnvironment.name": function (value) {
      if(value != undefined){
        return Validator.value(value).required();
      }
    },
    "appEnvironment.title": function (value) {
      if(value != undefined){
      return Validator.value(value).required();
      }
    },
    "appEnvironment.url": function (value) {
      if(value != undefined){
      return Validator.value(value).required().url().custom(() =>
      {
      if (!Validator.isEmpty(value)) {
        var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,3})))(?::\d{2,3})?(?:\/\S*)?$/;
          if (!regexp.test(value)) {
              return 'Invalid url';
          }
        }
      });
    }
    }
  }
};
</script>
<style scoped>
.floatlabel {
  border-radius: 2px;
  font-weight: 700;
  font-size: 13px !important;
}
.ptext-overflow{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:400px !important;
  font-weight: 400;
}
.pdesc-overflow{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:1200px !important; 
  font-weight: 400 ;  
}
.overflowlabel{
    font-weight: bold !important;
}
</style>