<template>
 <div class="appskeleton">
       <div class="p-fluid p-grid">
        <div class="p-field p-col-12 p-md-8 p-pt-4 p-pl-4 p-pr-4">
            <Skeleton  height="3rem"></Skeleton>
        </div>
      </div>
      <div class="p-fluid p-grid">
        <div class="p-field p-col-12 p-md-4 p-m-0">
            <Skeleton  height="3rem"></Skeleton>
        </div>
        <div class="p-field p-col-12 p-md-4 p-m-0">
            <Skeleton  height="3rem"></Skeleton>
        </div>
        <div class="p-field p-col-12 p-md-4 p-m-0">
            <Skeleton  height="3rem"></Skeleton>
        </div>
      </div>
      <div class="p-fluid p-grid">
        <div class="p-field p-col-12 p-md-4 p-m-0">
            <Skeleton  height="3rem"></Skeleton>
        </div>
        <div class="p-field p-col-12 p-md-4 p-m-0">
            <Skeleton  height="3rem"></Skeleton>
        </div>
        <div class="p-field p-col-12 p-md-4 p-m-0">
            <Skeleton  height="3rem"></Skeleton>
        </div>
      </div>
      <div class="p-fluid p-grid">
        <div class="p-field p-col-12 p-md-8 p-p-4">
            <Skeleton  height="3rem"></Skeleton>
        </div>
      </div>
      <div class="p-fluid p-grid">
        <div class="p-field p-col-12 p-md-8 p-p-4">
            <Skeleton  height="3rem"></Skeleton>
        </div>
      </div>
        <div class="card">
        <TabView ref="createapp" :activeIndex="0">
          <TabPanel>
              <template #header>
                 <Skeleton  width="7rem" height="1rem"></Skeleton>
              </template>
              <Skeleton  height="10rem"></Skeleton>
          </TabPanel>
          <TabPanel>
              <template #header>
                <Skeleton width="7rem" height="1rem"></Skeleton>
            </template>
              <Skeleton  height="10rem"></Skeleton>
           </TabPanel>
          <TabPanel>
              <template #header>
                <Skeleton width="7rem" height="1rem"></Skeleton>
            </template>
              <Skeleton  height="10rem"></Skeleton>
           </TabPanel>
          <TabPanel>
              <template #header>
                <Skeleton width="7rem" height="1rem"></Skeleton>
            </template>
              <Skeleton  height="10rem"></Skeleton>
           </TabPanel>
          <TabPanel>
              <template #header>
                <Skeleton width="7rem" height="1rem"></Skeleton>
            </template>
              <Skeleton  height="10rem"></Skeleton>
          </TabPanel>
        </TabView>
      </div>
 </div>
</template>
<script>
export default {
  name: "skeletonCreateApp",
  created() {},
  components: {},
  methods: {},
  props: {},
  watch: {},
  data() {
    return {};
  },
};
</script>
<style scoped>
</style>